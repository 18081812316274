<template>
  <div class="vca-column">
    <vca-accordion :header="$t('faqs.pool.city.header')">
      <div slot="body" v-html="$t('faqs.pool.city.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.pool.active.header')">
      <div slot="body" v-html="$t('faqs.pool.active.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.pool.data.header')">
      <div slot="body" v-html="$t('faqs.pool.data.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.pool.what.header')">
      <div slot="body" v-html="$t('faqs.pool.what.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.pool.bug.header')">
      <div slot="body" v-html="$t('faqs.pool.bug.body')" />
    </vca-accordion>
  </div>
</template>
<script>
export default {
  name: "FaqsPool",
};
</script>
